import React, { useState, useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { Slice, AppLayout, LoadingCard } from 'components';
import { useApolloClient, useQuery } from '@apollo/client';
import Cookies from 'js-cookie';
import { IS_USER_LOGGED_IN } from '../apollo/queries/queries_misc';
import { GET_USER } from '../apollo/queries/queries_ff';
import { isBrowser } from '../utils/isBrowser';

const ApplicationFlow = ({ location, data, pageContext }) => {
  const { page } = data;
  const { data: pageData, first_publication_date: publishedDate, last_publication_date: updatedDate } = page;
  const { body: sliceData, meta_title: metaTitle, meta_description: metaDesc, open_graph_image: ogImage } = pageData;

  const seo = {
    title: metaTitle?.text,
    desc: metaDesc?.text,
    banner: ogImage?.url,
    publishedDate,
    updatedDate,
  };

  const { verificationRequired, isLeadFlow, isRatesFlow, isInviteFlow, uid } = pageContext;

  const stepIndex = pageData?.flow_step_index;

  const apolloClient = useApolloClient();

  const [showLoading, setShowLoading] = useState(false);

  const { data: authCheckData, loading: authCheckLoading } = useQuery(IS_USER_LOGGED_IN);

  const isLoggedIn = authCheckData?.isLoggedIn || !!Cookies.get('yb-token');

  const {
    loading: applicantCoreDataLoading,
    error,
    data: applicantCoreData,
    refetch: refetchApplicantCoreData,
    networkStatus,
  } = useQuery(GET_USER, {
    variables: {
      email: Cookies.get('yb-email'),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  const currentFlowStep = applicantCoreData?.currentUser?.currentFlowStep || 0;

  useEffect(() => {
    localStorage.setItem('yb-current-flow-step', currentFlowStep);
  }, [currentFlowStep]);

  // const isUserVerified = applicantCoreData?.currentUser?.userVerified;
  const isUserVerified = !!applicantCoreData?.currentUser.application.Users.find(
    (user) => user.aboutUser.idVerified && user.aboutUser.bankStatementsReceived
  );

  const isRefinancing = applicantCoreData?.currentUser?.application?.isRefinance;

  const gcsData = isBrowser && JSON.parse(localStorage.getItem('yb-gcs-data'))?.gcsData;

  const leadFlowRedirect = (userRefinancing) => {
    if (!gcsData?.id && (uid === 'purchase-gcs' || uid === 'refinance-gcs')) {
      return navigate(`/${uid?.slice(0, -4)}`);
    }

    if (gcsData?.id && (uid === 'purchase' || uid === 'refinance')) {
      navigate(`/${uid}-gcs`);
      return null;
    }

    if (!isLoggedIn) return null;

    if (uid === 'get-started') {
      if (userRefinancing) return navigate('/refinance');

      return navigate('/purchase');
    }

    if (uid === 'purchase' && userRefinancing) return navigate('/refinance');

    if (uid === 'refinance' && !userRefinancing) return navigate('/purchase');
  };

  if (!isLeadFlow && !authCheckLoading && !isLoggedIn) {
    navigate('/get-started');
    return null;
  }

  if (!applicantCoreDataLoading && !isUserVerified && verificationRequired) {
    navigate('/login');
    return null;
  }

  if (isLoggedIn && (showLoading || applicantCoreDataLoading || authCheckLoading)) {
    return (
      <AppLayout seo={seo} showTrustFactors stepIndex={stepIndex}>
        <LoadingCard />
      </AppLayout>
    );
  }

  if (isLeadFlow) {
    leadFlowRedirect(isRefinancing);
  }

  const extraProps = {
    applicantCoreData,
    location,
    isLoggedIn,
    showLoading,
    setShowLoading,
    apolloClient,
    refetchApplicantCoreData,
    isLeadFlow,
    isRatesFlow,
    isInviteFlow,
  };

  return (
    <AppLayout seo={seo} showTrustFactors stepIndex={stepIndex} extra={extraProps}>
      {sliceData.map((slice) => (
        <Slice key={slice.id} pageContext={pageContext} data={slice} extra={extraProps} />
      ))}
    </AppLayout>
  );
};

export default ApplicationFlow;

export const pageQuery = graphql`
  query ApplicationFlowBySlug($uid: String!) {
    page: prismicApplicationFlow(uid: { eq: $uid }) {
      id
      uid
      prismicId
      first_publication_date
      last_publication_date
      data {
        meta_description {
          text
          html
        }
        meta_title {
          text
          html
        }
        open_graph_image {
          url
        }
        flow_step_index
        parent {
          url
          uid
          slug
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
        body {
          ...ApplicationFlowQuestions
          ...ApplicationFlowListApplicationFlows
          ...ApplicationFlowFlowHeader
          ...ApplicationFlowFlowStage
          ...ApplicationFlowInviteApplicant
        }
      }
    }
  }
`;
